// https://codesandbox.io/s/oxy3e?file=/pages/profile.js:192-204
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useReducer,
} from "react";
import Router from "next/router";
import nextCookie from "next-cookies";
import axios from "axios";
import cookie from "js-cookie";

export const login = (token, path = "/") => {
  if (process.env.NODE_ENV === "development") {
    window.localStorage.setItem("aide_token", token);
  } else {
    window.localStorage.setItem("aide_token", token);
  }
  Router.replace(path);
};

export const auth = (ctx) => {
  if (typeof window === "undefined") {
    // Server side
  } else {
    // Client side
    if (!window.localStorage.getItem("aide_token")) {
      Router.replace("/login");
    }
  }

  /*const { aide_token } = nextCookie(ctx);

  // If there's no token, it means the user is not logged in.
  if (!aide_token) {
    if (typeof window === "undefined") {
      ctx.res.writeHead(302, { Location: "/login" });
      ctx.res.end();
    } else {
      Router.replace("/login");
    }
  }

  return aide_token;*/
};

export const unAuth = (ctx) => {
  if (typeof window === "undefined") {
    // Server side
  } else {
    // Client side
    if (window.localStorage.getItem("aide_token")) {
      Router.replace("/");
    }
  }

  /*const { aide_token } = nextCookie(ctx);

  // If there is aide_token then redirect to dashboard
  if (aide_token) {
    if (typeof window === "undefined") {
      ctx.res.writeHead(302, { Location: "/" });
      ctx.res.end();
    } else if (cookie.get("aide_token")) {
      Router.replace("/");
    }
  }

  return aide_token;*/
};

/**
 * Redirect un-authenticated pages if user is logged in
 * Useful on pages like login, register
 */
export const withoutAuthSync = (WrappedComponent) => {
  const Wrapper = (props) => {
    return <WrappedComponent {...props} />;
  };

  Wrapper.getInitialProps = async (ctx) => {
    const token = unAuth(ctx);

    const componentProps =
      WrappedComponent.getInitialProps &&
      (await WrappedComponent.getInitialProps(ctx));

    return { ...componentProps, token };
  };

  return Wrapper;
};

/**
 * Protected authenticated pages
 */
export const withAuthSync = (WrappedComponent) => {
  const Wrapper = (props) => {
    const syncLogout = (event) => {
      if (event.key === "logout") {
        Router.replace("/login");
      }
    };

    useEffect(() => {
      if (!window.localStorage.getItem("aide_token")) {
        Router.replace("/login");
      }
      window.addEventListener("storage", syncLogout);

      return () => {
        window.removeEventListener("storage", syncLogout);
        window.localStorage.removeItem("logout");
      };
    });

    return <WrappedComponent {...props} />;
  };

  Wrapper.getInitialProps = async (ctx) => {
    const token = auth(ctx);

    const componentProps =
      WrappedComponent.getInitialProps &&
      (await WrappedComponent.getInitialProps(ctx));

    return { ...componentProps, token };
  };

  return Wrapper;
};

const initialState = {
  user: null,
};

export const AuthContext = createContext(initialState);

const Reducer = (state, action) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: action.user,
      };
    default:
      return state;
  }
};

export const Context = createContext(initialState);

export const AuthProvider = ({
  showLoadingScreen,
  children,
  refreshUser,
  handleRefreshUser,
}) => {
  const [account, setAccount] = useState(null);
  const [loading, setLoading] = useState(false);

  const [state, dispatch] = useReducer(Reducer, initialState);

  useEffect(() => {
    window.addEventListener("message", function (event) {
      if (event.origin === "https://my.aide.app") {
        if (event.data) {
          localStorage.setItem("aide_token", event.data);
        }
      }
    });

    waitForCookies();
  }, []);

  useEffect(() => {
    let loginUpdate = setInterval(waitForCookies, 10000);

    return () => clearInterval(loginUpdate);
  }, [account]);

  const waitForCookies = () => {
    //const token = cookie.get("aide_token");
    const token = window.localStorage.getItem("aide_token");
    if (token) {
      if (!account) {
        accountSetup();
      }

      if (refreshUser) {
        handleRefreshUser(false);
      }
    } else {
      // Not logged in handle
    }
  };

  /**
   * Make sure the integration is connected and account is approved
   */
  const accountSetup = () => {
    axios.get("me").then((response) => {
      if (response.data) {
        const frontIntegration = response.data.integrations.find(
          (integration) => {
            return process.env.NEXT_PUBLIC_PLATFORM === integration.name;
          }
        );

        // Make the widget ready if, integration is connected, enabled, and we are not doing the onboarding
        if (
          response.data.team.is_approved &&
          frontIntegration &&
          frontIntegration.active_integration &&
          frontIntegration.active_integration.is_enabled &&
          !response.data.onboard_user
        ) {
          // Show welcome screen post login
          Router.replace("/single-conversation");
        } else if (response.data.team.show_onboarding) {
          // Set another call to our API
          //setTimeout(accountSetup, 5000);
          Router.replace("/onboard");
        } else if (!response.data.team.is_approved) {
          Router.replace("/onboard/complete");
        } else if (!frontIntegration) {
          Router.replace(
            `/integrations/${process.env.NEXT_PUBLIC_PLATFORM}/not-connected`
          );
        }

        // else if (
        //   frontIntegration &&
        //   !frontIntegration.active_integration.is_enabled
        // ) {
        //   Router.replace(
        //     `/integrations/${process.env.NEXT_PUBLIC_PLATFORM}/not-enabled`
        //   );
        // }

        dispatch({
          type: "SET_USER",
          user: response.data,
        });

        setAccount(response.data);
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!account,
        user: state.user,
        state: state,
        dispatch: dispatch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  return context;
}

export function ProtectRoute(Component) {
  return () => {
    const { isAuthenticated, user } = useAuth();
    const router = useRouter();

    useEffect(() => {
      if (!isAuthenticated && !loading) router.replace("/login");
    }, [loading, isAuthenticated]);

    return <Component {...arguments} />;
  };
}
