// @ts-nocheck
import { useEffect } from 'react'
import { useRouter } from 'next/router'

import { withoutAuthSync } from '../../contexts/auth'

import Button from 'src/components/ui/button'

import { Logo } from 'images/logo'

import { useJune } from 'hooks/june'

const Login = () => {
  const analytics = useJune()
  const router = useRouter()

  const openLoginPage = async () => {
    let width = 600
    let height = 700

    let LeftPosition = window.screen.width ? (window.screen.width - width) / 2 : 0
    let TopPosition = window.screen.height ? (window.screen.height - height) / 2 : 0
    let settings =
      'height=' + height + ',width=' + width + ',top=' + TopPosition + ',left=' + LeftPosition

    window.open(
      `${
        process.env.NODE_ENV === 'production' ? 'https://my.aide.app' : 'http://localhost:3001'
      }/login?source=${process.env.NEXT_PUBLIC_PLATFORM}`,
      'popUpWindow',
      settings
    )
  }

  useEffect(() => {
    window.addEventListener(
      'message',
      function (event) {
        const sourceOrigin =
          process.env.NODE_ENV === 'production' ? 'https://my.aide.app' : 'http://localhost:3001'

        if (event.origin === sourceOrigin) {
          if (event.data) {
            let finalCookie = JSON.parse(Buffer.from(event.data, 'base64').toString())
            localStorage.setItem('aide_token', finalCookie.message)

            if (analytics) {
              analytics.track(
                `${process.env.NEXT_PUBLIC_PLATFORM == 'front' ? 'Front' : 'Zendesk'} Login`
              )
            }
          }
        }
      },
      false
    )
  }, [])

  /**
   * Run interval to listen for token in localstorage, i.e login
   */
  useEffect(() => {
    let loginUpdate = setInterval(() => {
      const token = window.localStorage.getItem('aide_token')
      if (token) {
        if (router.pathname.includes('login')) {
          router.replace('/single-conversation')
        }
      }
    }, 500)

    return () => clearInterval(loginUpdate)
  }, [])

  return (
    <div>
      <div className="max-w-xs m-auto">
        <div className="mx-3 py-8 px-6 pb-4 border-b border-black/5">
          <div className="flex justify-center mt-1 mb-4">
            <Logo />
          </div>
          <p className="text-center text-[17px] text-[#444444] dark:text-white/80 font-semibold mb-2">
            Welcome to Aide
          </p>
          <p className="text-center text-[13px] text-black/40 dark:text-white/60 font-[450]">
            See intents and AI suggestions
          </p>
          <div className="flex gap-2.5 justify-center mt-5 mb-3 mx-auto">
            <a
              className="min-w-[88px] justify-center rounded-md text-xs px-4 py-2  border font-[520] border-black/20 shadow-button text-[#EDEDED] rounded-button bg-[#444] hover:bg-black/80 hover:shadow-none transition ease-in-out duration-200 text-center"
              target="_blank"
              rel="noopener noreferrer"
              href="https://my.aide.app/register"
            >
              Sign up
            </a>
            <Button width="min-w-[88px]" variant={Button.variant.OUTLINE} onClick={openLoginPage}>
              Log in
            </Button>
          </div>
        </div>
      </div>

      <div className="mt-8 text-center font-medium">
        <p className="text-xs text-black/40 dark:text-white/60 mb-5">Want to learn more?</p>
        <a
          className="min-w-[88px] justify-center rounded-md text-xs mt-4 px-4 py-2.5  bg-white border font-[550] border-black/[0.15] shadow-button-outline text-black/60 hover:opacity-90 hover:shadow-none transition ease-in-out duration-200 hover:transition-all leading-3"
          target="_blank"
          rel="noopener noreferrer"
          href="https://calendly.com/ziyad-aide/30min"
        >
          Request a demo
        </a>
      </div>
    </div>
  )
}

export default withoutAuthSync(Login)
