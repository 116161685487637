import { MouseEventHandler } from 'react'
import { classNames } from 'utils/classNames'

import Spinner from 'images/spinner.svg'

enum Variant {
  DANGER,
  PRIMARY,
  BLACK,
  MACRO,
  APPLY,
  DRAFT,
  AUTO,
  OUTLINE,
  LINK,
}

type Props = {
  id?: string
  loading?: string
  disabled?: boolean
  variant: Variant
  width?: string
  children?: React.ReactNode
  onClick?: MouseEventHandler<HTMLButtonElement>
}

const VARIANT_MAPS: Record<Variant, string> = {
  [Variant.DANGER]:
    'bg-gradient-to-b from-danger-1 to-danger-2 border border-danger shadow-button text-white disabled:hover:opacity-75 hover:opacity-90 hover:shadow-none disabled:hover:shadow-none transition ease-in-out duration-200 hover:transition-all leading-3',
  [Variant.PRIMARY]:
    'bg-gradient-to-b from-green-1 to-green-2 border border-green-3 shadow-button text-white disabled:hover:opacity-75 hover:opacity-90 hover:shadow-none disabled:hover:shadow-none transition ease-in-out duration-200 hover:transition-all leading-3',
  [Variant.BLACK]:
    'bg-[#444] border font-[580] border-black/20 shadow-button text-[#EDEDED] disabled:hover:opacity-75 hover:bg-black/80 hover:shadow-none disabled:hover:shadow-none transition ease-in-out duration-200 hover:transition-all leading-3',
  [Variant.MACRO]:
    '!bg-[#444] border font-[580] border-black/20 shadow-button text-[#EDEDED] disabled:hover:opacity-75 hover:bg-black/80 hover:shadow-none disabled:hover:shadow-none transition ease-in-out duration-200 hover:transition-all leading-3 mt-0 pt-[7px] pb-[7px] ',
  [Variant.APPLY]:
    '!bg-[#DEA732]/70 border font-[580] border-[#DEA732] shadow-button text-[#FFFFFF] disabled:hover:opacity-75 hover:bg-[#DEA732] hover:shadow-none disabled:hover:shadow-none transition ease-in-out duration-200 hover:transition-all leading-3 mt-0 py-1.5',
  [Variant.DRAFT]:
    'bg-[#53A692]/10 font-[580] text-[#53A692] disabled:hover:opacity-75 hover:bg-[#5DB49F] hover:text-white hover:shadow-none disabled:hover:shadow-none transition-all ease-in-out duration-200 leading-3 mt-0 py-2.5 hover:scale-[98.75%]',
  [Variant.AUTO]:
    '!bg-[#DEA732]/80 border font-[580] border-black/10 shadow-button text-white disabled:hover:opacity-75 hover:bg-black/80 hover:shadow-none disabled:hover:shadow-none transition ease-in-out duration-200 hover:transition-all leading-3 mt-0 pt-[7px] pb-[7px] cursor-not-allowed ',
  [Variant.OUTLINE]:
    'bg-white border font-[550] border-black/10 shadow-sm py-2 text-black/60 hover:opacity-90 hover:shadow-none transition ease-in-out duration-200 hover:transition-all leading-3',
  [Variant.LINK]:
    'text-[13px] font-medium text-green-3/90 !p-0 hover:opacity-75 hover:transition-all',
}

const Button = (props: Props) => {
  const { children, disabled, loading, variant, width } = props
  return (
    <button
      id={props.id}
      className={classNames(
        width,
        'justify-center rounded-[8px] font-[550] text-[11.5px] px-4',
        VARIANT_MAPS[variant]
      )}
      onClick={props.onClick}
      disabled={disabled}
    >
      {loading ? (
        <span className="flex items-center justify-center gap-2">
          <Spinner className="animate-spin" /> {loading}
        </span>
      ) : (
        children
      )}
    </button>
  )
}

Button.defaultProps = {
  variant: Variant.PRIMARY,
}

Button.variant = Variant

export default Button
